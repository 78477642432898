import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BannerImg from "../components/BannerImg";
import ContactCard from "../components/ContactCard";

function Contact() {
    return (
        <>
            <Header />
            <ContactCard />
            <Footer />
        </>


    )
}

export default Contact;